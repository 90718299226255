import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {IPatientDetails} from "../../models";
import {useNavigate, useParams} from "react-router-dom";
import {fetchPatient} from "../../store/actions/screenerQuestions.action";
import {DateTime} from "luxon";
import {PatientScreenerStatusPill} from "../generic/PatientScreenerStatusPill";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import { clearUserScreenerId } from "../../store/actions/account.action";

const PatientScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();
    const {patient} = useSelector((state: StateParams) => state.screener)

    useEffect(() => {
        if (params.id) {
            dispatch(fetchPatient(params.id))
            dispatch(clearUserScreenerId())
        }
    }, [params.id]);

    const renderPatientDetails = (patient: IPatientDetails) => {
        return <div className="flex flex-col mt-3 p-2">
            <div className="grid grid-cols-4 gap-4">
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> First Name </span>
                    <span className={"text-md "}>{patient.firstName}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> Last Name </span>
                    <span className={"text-md "}>{patient.lastName}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> Gender </span>
                    <span className={"text-md "}>{patient.gender}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> DOB </span>
                    <span className={"text-md "}>{formatDob(patient.dob)}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> Phone Number </span>
                    <span className={"text-md "}>{patient.contactPhoneNumber}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> EMR Number </span>
                    <span className={"text-md "}>{patient.emrId ? patient.emrId : "N/A"}</span>
                </div>
                <div className="flex flex-col mt-1">
                    <span className={"text-md font-semibold text-gray-700"}> Zip code </span>
                    <span className={"text-md "}>{patient.zipCode}</span>
                </div>
            </div>
            <div className="flex flex-row flex-wrap mt-5">
                <PatientScreenerStatusPill value={{
                    hasPendingOrElapsedScreeners: patient.hasPendingOrElapsedScreeners,
                    isEligibleForScreeningBasedOnStatus: patient.isEligibleForScreeningBasedOnStatus,
                    userId: patient.id,
                    dob: patient.dob

                }}/>
            </div>
            <div className="flex flex-row flex-wrap max-h-[500px] min-w-[100%] overflow-y-auto">
                {
                    patient.screenerHistory.map(screenerHistoryItem => {
                        return <div className={"flex flex-col mt-5 min-w-[100%]"} key={screenerHistoryItem.visit}>
                            <hr/>
                            <div className="flex flex-col mt-2">
                                <span className={"text-md font-semibold text-gray-700"}> Visit Date </span>
                                <span className={"text-md "}>{formatDateTime(screenerHistoryItem.createdAt)}</span>
                            </div>
                            <div className="flex flex-col mt-2">
                                <span className={"text-md font-semibold text-gray-700"}> Status </span>
                                <span
                                    className={"text-md "}>{screenerHistoryItem.isComplete ? "Complete" : "Incomplete"}</span>
                            </div>
                            <div className="flex flex-col mt-2">
                                <span className={"text-md font-semibold text-gray-700"}> Screeners </span>
                                {screenerHistoryItem.screeners.map(screener => {
                                    const isComplete = screener.isComplete ? "Complete" : "Incomplete"
                                    return <div className={"flex"} key={screener.id}>
                                        <div className="flex flex-row space-x-2">
                                            <span
                                                className={"text-md text-gray-700"}> {screener.slug} </span>
                                            <span
                                                className={`text-md font-semibold ${screener.isComplete ? "text-sjGreen" : "text-red-600"}`}>{isComplete}</span>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    const formatDob = (dob: Date) => DateTime.fromISO(dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
    const formatDateTime = (dob: Date) => DateTime.fromISO(dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy HH:mm')

    return (
        <main className="h-screen mx-auto px-1 bg-sky-light-lg bg-no-repeat bg-cover">
            <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" onClick={() => {
                navigate("/patients?page=1")
            }}/>
            <div className="flex space-x-10 pt-2">
                <h1 className="text-xl font-semibold"> Patient Details </h1>
            </div>
            {patient ? renderPatientDetails(patient) : null}
        </main>
    )
}

export default PatientScreen
