import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setLanguagePreference
} from "../../store/actions/account.action";
import 'react-phone-number-input/style.css'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import { LanguageCodes } from '../../models'
import { StateParams } from "../../store/reducers";
import PracticeEntry from "./PracticeEntry";

const withPreferredLanguageSelection = (WrappedComponent: any) => (props: any) => {
    const dispatch = useDispatch()
    const { languagePreference } = useSelector((state: StateParams) => state.account)
    const [languageOnClick, setLanguageOnClick] = useState<boolean>(false)

    const onLanguageSelection = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedLanguage = e.target.value as LanguageCodes
        if ([LanguageCodes.EN_US, LanguageCodes.ES, LanguageCodes.PL, LanguageCodes.HI, LanguageCodes.GU, LanguageCodes.RU].includes(selectedLanguage)) {
            // @ts-ignore // the IF check will handle it
            dispatch(setLanguagePreference({ languagePreference: selectedLanguage }))
        } else {
            dispatch(setLanguagePreference({ languagePreference: LanguageCodes.EN_US }))
        }
    }

    const submitPreferredLanguage = useCallback(() => {
        if (languagePreference) {
            setLanguageOnClick(true)
        }
    }, [languagePreference])

    return (
        <>
            {(languageOnClick && languagePreference) ?
                <PracticeEntry />
                :
                <div className='h-screen md:w-full mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                    <div className='w-full h-32 flex items-center justify-center'>
                        <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle" />
                    </div>
                    <div className='w-full h-72 flex flex-col items-center justify-center'>
                        <p className='text-lg font-bold'>{'Preferred Language (Idioma Preferido)'}</p>
                        <div className='flex mt-8 flex-wrap content-center justify-center'>
                            <div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>
                                <input id='englishLP' type="radio" required name='languagePreference' value={LanguageCodes.EN_US} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
                                <label htmlFor={'englishLP'} className='text-sm'>English</label>
                            </div>
                            <div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>
                                <input id='spanishLP' type="radio" required name='languagePreference' value={LanguageCodes.ES} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
                                <label htmlFor={'spanishLP'} className='text-sm'>Spanish</label>
                            </div>
                            {/*<div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>*/}
                            {/*    <input id='polishiLP' type="radio" required name='languagePreference' value={LanguageCodes.PL} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />*/}
                            {/*    <label htmlFor={'polishiLP'} className='text-sm'>Polish</label>*/}
                            {/*</div>*/}
                            {/*<div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>*/}
                            {/*    <input id='hindiLP' type="radio" required name='languagePreference' value={LanguageCodes.HI} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />*/}
                            {/*    <label htmlFor={'hindiLP'} className='text-sm'>Hindi</label>*/}
                            {/*</div>*/}
                            {/*<div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>*/}
                            {/*    <input id='gujaratiLP' type="radio" required name='languagePreference' value={LanguageCodes.GU} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />*/}
                            {/*    <label htmlFor={'gujaratiLP'} className='text-sm'>Gujarati</label>*/}
                            {/*</div>*/}
                            {/*<div className='flex items-center p-4 bg-white rounded-md mr-4 mt-2'>*/}
                            {/*    <input id='russianLP' type="radio" required name='languagePreference' value={LanguageCodes.RU} onChange={onLanguageSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />*/}
                            {/*    <label htmlFor={'russianLP'} className='text-sm'>Russian</label>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <WrappedComponent {...props} submitPreferredLanguage={submitPreferredLanguage} />
                </div>
            }
        </>
    )
}

export default withPreferredLanguageSelection
