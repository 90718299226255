import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {getCurrentQuestion} from '../../store/actions/screenerQuestions.action';
import {useDispatch, useSelector} from 'react-redux';
import {StateParams} from '../../store/reducers';

const ScreenerInit = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { tobeAnsweredQuestion } = useSelector((state: StateParams) => state.screener)
    const { userScreenerId, userScreenerSlug } = useSelector((state: StateParams) => state.account)

    useEffect(() => {
        if (!userScreenerId) return navigate('/thankYou')
        dispatch(getCurrentQuestion({
            screenerId: userScreenerId
        }))
    }, [dispatch, navigate, userScreenerId])

    useEffect(() => {
        if(tobeAnsweredQuestion?.id && (userScreenerSlug === 'adhd-12to25' || userScreenerSlug === 'fp-adhd-12to18') && tobeAnsweredQuestion.order === 1){
            navigate('/adhdNote')
        } else if (tobeAnsweredQuestion?.id) {
            const questionId = tobeAnsweredQuestion.id
            navigate(`/screener/${userScreenerId}/question/${questionId}`)
        } else if (tobeAnsweredQuestion === null) {
            navigate('/thankYou')
        }
    }, [tobeAnsweredQuestion?.id])

    return (<>
        <div className="static-full-screen">
            <h1>Initializing Screener...</h1>
        </div>
    </>)
}

export default ScreenerInit
