import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearErrorMessages, logoutUser, setLanguagePreference
} from "../../store/actions/account.action";
import { StateParams } from "../../store/reducers";
import 'react-phone-number-input/style.css'
import { clearScreenerQuestions } from "../../store/actions/screenerQuestions.action";
import { clearAllData } from "../../store/actions/signupData.action";
import withPreferredLanguageSelection from "./withPreferredLanguageSelection";

interface Props {
    submitPreferredLanguage: () => void
}

const PreferredLanguageSelection: React.FC<Props> = ({submitPreferredLanguage}) => {

    const dispatch = useDispatch();
    const { checkPhoneExistsError } = useSelector((state: StateParams) => state.practice)

    useEffect(() => {
        dispatch(logoutUser())
        dispatch(clearAllData())
        dispatch(clearErrorMessages())
        dispatch(clearScreenerQuestions())
    }, [])

    return (
        <>
            {
                <>
                    <div className='w-full flex flex-col items-center justify-center'>
                        <button data-test="practice-continue"
                            className='md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white'
                            onClick={submitPreferredLanguage}>Continue
                        </button>
                        {checkPhoneExistsError && <span
                            className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{checkPhoneExistsError}</span>}
                    </div>
                </>
            }
        </>
    )
}

export default withPreferredLanguageSelection(PreferredLanguageSelection)
