import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {accountSignInFailed, logoutUser, patientTempLogin, setScreenName} from "../../store/actions/account.action";
import {StateParams} from "../../store/reducers";
import {saveLanguagePreference} from "../../store/actions/screenerQuestions.action";
import withPreferredLanguageSelection from "../home/withPreferredLanguageSelection";
import {MixpanelContext} from "../../analytics/Mixpanel";

const PatientScreeningEntryScreen = () => {

    const analytics = React.useContext(MixpanelContext);
    const {
        patientId,
        practiceId
    } = useParams();
    const {
        jwt,
        signInError,
    } = useSelector((state: StateParams) => state.account);
    const [invalidLink, setInvalidLink] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (practiceId && patientId) {
            dispatch(patientTempLogin({
                practiceId, patientId
            }));
        } else {
            setInvalidLink(true);
        }
    }, [practiceId, patientId]);

    useEffect( () => {
        if (jwt) {
            console.log("sending mixpanel analytics for opened_screener_link_via_sms");

            // Wrap Mixpanel track event in a Promise with a fallback timeout
            const trackEvent = new Promise((resolve) => {
                analytics?.identify(patientId);
                analytics?.track("opened_screener_link_via_sms", { practiceId, patientId }, resolve);
            });

            const timeout = new Promise((resolve) =>
                setTimeout(() => {
                    console.warn("Mixpanel tracking timed out; proceeding to /patientProfile.");
                    resolve(null);
                }, 500)
            );

            // Navigate to patient profile when either event tracking or timeout finishes
            Promise.race([trackEvent, timeout]).then(() => {
                navigate('/patientProfile');
            });
        }
    }, [jwt, analytics, patientId, practiceId]);

    return (
        <div className={"static-full-screen"}>
            {
                (signInError || invalidLink) &&
                <h1>Screener Link is invalid, please contact your provider</h1>
            }
        </div>
    );
}

export default PatientScreeningEntryScreen;
