export interface IDebug {
    message: string
    statusCode: number
}

export interface IScreener {
    id: string
    content: string
    order: number
    slug: string
    createdBy: string
}

export interface IScreenerAnswer {
    id: string
    screenerQuestionId: string
    content: string
    spanishContent: string
    order: number
    createdAt: string
    updatedAt: string
    type: AnswerType
}

export interface IUserScreenerAnswer {
    id: string
    screenerId: string
    questionId: string
    userId: string
    answerId: string
    additionalInfo?: any
}

export interface UserScreenerMultiSelect {
    answerId: string;
    response: string | boolean;
}

export interface IScreenerQuestion {
    id: string
    content: string
    spanishContent: string
    order: number
    screenerId: string
    type: string
    slug: string
    screenerAnswers: IScreenerAnswer[]
    userScreenerAnswers? : IUserScreenerAnswer[]
    createdAt: string
    updatedAt: string
    headerContent?: string
}

export type AllScreenerQuestion = Omit<IScreenerQuestion, "screenerAnswers">

export interface IScreenerQuestionDetails {
    currentQuestion: IScreenerQuestion,
    allScreenerQuestions: AllScreenerQuestion[]
}

export interface IScreenerCurrentData {
    allScreenerQuestions: AllScreenerQuestion[]
    isScreenerCompleted: boolean
    tobeAnsweredQuestion: IScreenerQuestion
}

export interface IScreenerAnswerResponse {
    nextQuestionLink: string
    nextScreenerId?: string
    nextScreenerSlug?: string
    isScreenerPositive?: boolean
}

export interface ICreateAccountRequest {
    firstName: string
    lastName: string
    emrId?: string
    phoneNumber: string
    password: string
    dob: string
    hasAcceptedTerms: boolean
    isLegalGuardian: boolean
    isOneAmongMultipleChildren: boolean
    hasPrematureBirth: boolean
    zipCode: string
    reasonForVisit: string
    email?: string
    guardianFirstName?: string
    guardianLastName?: string
    guardianEmail?: string
    gender: string
    ageGroup?: AgeGroup
    preferredLanguage?: LanguageCodes
}

export interface CreateAccountAPIRequest extends ICreateAccountRequest {
    practiceId: string
}

export interface IPractice {
    practiceId: string
    error?: string
}

export interface IUserAccount {
    jwt: string
    id: string
    phoneNumber: string
    nickname: string
    userScreenerId: string
    userScreenerSlug: string,
    hasAcceptedTerms: boolean
    preferredLanguage: LanguageCodes
    error?: string
}

export interface PatientScreener extends Pick<IUserAccount, "id" | "userScreenerId" | "userScreenerSlug" | "error"> {}

export enum VisitTypeCodes {
	Cognitive = 'cognitive',
	GeneralScreeners = 'others'
}
export interface PatientScreeningVisit {
    lastScreenedOn: string
    nextScreenerAvailableOn: string
    areScreenersAvailable: boolean
    visitType: VisitTypeCodes
    isInsuredByMedicare: boolean
}

export interface ISignupData {
    firstName: string
    lastName: string
    phoneNumber: string
    password: string
    dob: string
    hasAcceptedTerms: boolean
    isLegalGuardian: boolean
    isOneAmongMultipleChildren: boolean
    hasPrematureBirth: boolean
    zipCode: string
    signupData: SignupData
    guardianData: GuardianData
}

export interface ICheckIfPhoneNumberExists {
    exists: boolean
    firstName: string
    lastName: string
    dob: string
    preferredLanguage: LanguageCodes
}

export interface ICheckIfEMRNumberExists extends ICheckIfPhoneNumberExists {}

export interface ICheckIfVerificationCodeSentSuccessfully {
    isSentSuccessfully: boolean
}

export interface ICheckIfCodeVerified {
    isVerified: boolean
}

export enum StackNames {
    FORGOT_PASSWORD = 'forgotPassword',
    SIGN_UP = 'signUp'
}

export enum Gender {
    MALE = 'male',
    FEMALE = 'female'
}

export enum LanguageCodes {
    EN_US = 'en_us',
    ES = 'es',
    PL = 'pl',
    HI = 'hi',
    GU = 'gu',
    RU = 'ru'
}
export enum AgeGroup {
    BELOW_TWELVE,
    TWELVE_TO_EIGHTEEN,
    ABOVE_EIGHTEEN
}

export enum AnswerType {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    TEXTBOX = 'textbox'
}

export interface IProviderUser {
    email: string;
    password: string;
    slug?: string
    practiceId?: string
}

export interface IPatientSummary {
    id: string
    firstName: string
    lastName: string
    zipCode: string
    phoneNumber: string
    contactPhoneNumber: string
    gender: Gender | null
    dob: Date
}
export interface IPatientResponse {
    data: IPatientSummary[],
    recordsCount: number
    pageNumber: number,
    totalPages: number,
    nextPage: number
}
export interface PatientScreenerIndex {
    score: ScreenerScore
    slug: string
    id: string
    isComplete: boolean
    isSuicidal: boolean
    createdAt: string
}

export interface ScreenerScore {
    totalScore: {[key: string]: number}
    conditionsScore: {[key: string]: number}
}
export interface PatientScreenerHistoryView {
    visit: number
    createdAt: Date
    isComplete: boolean
    screeners: PatientScreenerIndex[]
}
export interface IPatientDetails {
    id: string
    emrId: string | null
    firstName: string
    lastName: string
    zipCode: string
    phoneNumber: string
    contactPhoneNumber: string
    screeners: PatientScreenerIndex[]
    screenerHistory: PatientScreenerHistoryView[]
    gender: Gender | null
    dob: Date
    guardianEmail: string | null
    guardianFirstName: string | null
    guardianLastName: string | null
    guardianPhoneNumber: string | null
    hasPendingOrElapsedScreeners: boolean
    isEligibleForScreeningBasedOnStatus: boolean
    preferredLanguage: LanguageCodes
}
export interface IPatientDetailsResponse {
    data: {
        patient: IPatientDetails
    }
}

export interface IPatient {
    id: string
    firstName: string
    reasonForVisit: string
    lastName: string
    zipCode: string
    phoneNumber: string
    screeners: IPatientScreenerIndex[]
    isExpired?: boolean
    patientStatus: any
    gender: Gender | null
    dob: Date
    guardianEmail: string | null
    guardianFirstName: string | null
    guardianLastName: string | null
    guardianPhoneNumber: string | null
    isLegalGuardian: boolean
    hasAcceptedTerms: boolean
    hasPendingOrElapsedScreeners?: boolean
}

export interface IPatientScreenerIndex {
    score: IScreenerScore
    slug: string
    id: string
    isComplete: boolean
    isSuicidal: boolean
    createdAt: string
    nextVisitTime: number | null
    updatedAt: string
}

export interface IScreenerScore {
    totalScore: {[key: string]: number}
    conditionsScore: {[key: string]: number}
}

export interface IAccount {
    id: string
    jwt: string
    email: string
    phone: string
    slug: string
    zipCode: string
    name: string
    error?: string
    qrCodeUrl: string
    screenerUrl: string
    address: string
    practiceType: string
}

export type SignupData = Omit<ICreateAccountRequest, "reasonForVisit">
export type GuardianData = Pick<ICreateAccountRequest, "email" | "guardianFirstName" | "guardianLastName">
export type SignupDataNew = Pick<ICreateAccountRequest, "phoneNumber" | "hasAcceptedTerms" | "hasPrematureBirth" | "isOneAmongMultipleChildren" | "isLegalGuardian" | "preferredLanguage">

export const MULTI_CHOICE_QUESTION_SLUGS_METADATA = [
    'fp-drug-above18-select-the-drugs-you-have-consumed'
]

export const SCREENER_SLUGS = {
    Alcohol: 'fp-alcohol-above18'
}
