import { legacy_createStore as createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import {rootSaga} from "./sagas";
import {persistReducer, persistStore} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import {
    FETCH_CURRENT_QUESTION, FETCH_PATIENT,
    FETCH_PATIENTS,
    FETCH_QUESTION_DETAILS,
    SAVE_LANGUAGE_PREFERENCE,
    SAVE_USER_SCREENER_ANSWER
} from "./actions/screenerQuestions.action";
import {AccountParams} from "./reducers/account.reducer";
import {ACCEPT_TERMS, ACCOUNT_LOGIN, CREATE_ACCOUNT, PATIENT_SCREENER_START, PATIENT_SCREENER_VISITS, PROVIDER_LOGIN} from "./actions/account.action";
import { SignupParams } from "./reducers/signupData.reducer";
import { composeWithDevTools } from '@redux-devtools/extension';

const ACTIONS_REQUIRING_AUTH = [
    ACCOUNT_LOGIN,
    CREATE_ACCOUNT,
    FETCH_CURRENT_QUESTION,
    FETCH_QUESTION_DETAILS,
    SAVE_USER_SCREENER_ANSWER,
    FETCH_PATIENTS,
    FETCH_PATIENT,
    PROVIDER_LOGIN,
    PATIENT_SCREENER_START,
    PATIENT_SCREENER_VISITS,
    ACCEPT_TERMS,
    SAVE_LANGUAGE_PREFERENCE
]

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'account',
        'signup'
    ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const saga = createSagaMiddleware();

const logger = (store: any) => (next : any) => (action : any) => {
    return next(action)
}

const addToken = (store: any) => (next : any) => (action : any) => {
    if(ACTIONS_REQUIRING_AUTH.includes(action.type)) {
        const account : AccountParams = store.getState().account;
        const signupAccount : SignupParams = store.getState().signup;
        const token = account.jwt
        const userId = account.id
        const practiceId = account.practiceId
        const adminToken = account.provider?.jwt
        action.account = { token, userId, practiceId, adminToken }
    }
    return next(action)
}

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(logger, addToken, saga)));

saga.run(rootSaga);

let persistor = persistStore(store);
export {
    store,
    persistor,
};
