import {IAccount, IProviderUser, LanguageCodes, PatientScreeningVisit, VisitTypeCodes} from "../../models";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const ACCOUNT_CREATED = "ACCOUNT_CREATED"
export const ACCOUNT_CREATION_FAILED = "ACCOUNT_CREATION_FAILED"
export const ACCOUNT_LOGIN = "ACCOUNT_LOGIN"
export const ACCOUNT_SIGNED_IN = "ACCOUNT_SIGNED_IN"
export const ACCOUNT_SIGNIN_FAILED = "ACCOUNT_SIGNIN_FAILED"

export const PATIENT_TEMP_LOGIN = "PATIENT_TEMP_LOGIN"
export const PATIENT_TEMP_LOGGED_IN = "PATIENT_TEMP_LOGGED_IN"
export const PATIENT_TEMP_LOG_IN_FAILED = "PATIENT_TEMP_LOG_IN_FAILED"

export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE"
export const SEND_VERIFICATION_CODE_RESPONSE = "SEND_VERIFICATION_CODE_RESPONSE"
export const SEND_VERIFICATION_CODE_FAILED = "SEND_VERIFICATION_CODE_FAILED"
export const VERIFY_CODE = "VERIFY_CODE"
export const VERIFY_CODE_RESPONSE = "VERIFY_CODE_RESPONSE"
export const VERIFY_CODE_FAILED = "VERIFY_CODE_FAILED"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"
export const ACCOUNT_SIGN_OUT = "ACCOUNT_SIGN_OUT"
export const SET_NEXT_USER_SCREENER_ID = "SET_NEXT_USER_SCREENER_ID"
export const SET_SCREENER_SLUG = "SET_SCREENER_SLUG"
export const SET_PRACTICE_ID = "SET_PRACTICE_ID"
export const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES"
export const GET_PRACTICE_ID = "GET_PRACTICE_ID"
export const GET_PRACTICE_FAILED = "GET_PRACTICE_FAILED"
export const GET_PRACTICE_SUCCESS = "GET_PRACTICE_SUCCESS"
export const SET_PRACTICE_SLUG = "SET_PRACTICE_SLUG"
export const PROVIDER_SIGN_OUT = "PROVIDER_SIGN_OUT"
export const SET_SCREEN_NAME = "SET_SCREEN_NAME"

export const PROVIDER_LOGIN = "PROVIDER_LOGIN"
export const PROVIDER_LOGIN_FAILED = "PROVIDER_LOGIN_FAILED"
export const PROVIDER_LOGIN_SUCCESS = "PROVIDER_LOGIN_SUCCESS"
export const SET_USER_ID = "SET_USER_ID"
export const CLEAR_USER_ID = "CLEAR_USER_ID"
export const PATIENT_SCREENER_START = "PATIENT_SCREENER_START"
export const PATIENT_SCREENER_STARTED = "PATIENT_SCREENER_STARTED"
export const PATIENT_SCREENER_STARTING_FAILED = "PATIENT_SCREENER_STARTING_FAILED"
export const ACCEPT_TERMS = "ACCEPT_TERMS"
export const ACCEPT_TERMS_FAILED = "ACCEPT_TERMS_FAILED"
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS"

export const PATIENT_SCREENER_VISITS = "PATIENT_SCREENER_VISITS"
export const PATIENT_SCREENER_VISITS_FAILED = "PATIENT_SCREENER_VISITS_FAILED"
export const PATIENT_SCREENER_VISITS_SUCCESS = "PATIENT_SCREENER_VISITS_SUCCESS"
export const CLEAR_USER_SCREENER_ID = "CLEAR_USER_SCREENER_ID"

export const SET_LANGUAGE_PREFERENCE = "SET_LANGUAGE_PREFERENCE"

export interface SignupRequest {
    emrId?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    email?: string;
    dob: string;
    hasAcceptedTerms: boolean;
    isLegalGuardian: boolean;
    isOneAmongMultipleChildren: boolean;
    hasPrematureBirth: boolean;
    zipCode: string;
    reasonForVisit: string;
    guardianFirstName?: string;
    guardianLastName?: string;
    guardianEmail?: string;
    gender: string;
    isPatientCapable?: boolean;
    guardianPhoneNumber: string;
    relationshipWithPatient: string;
    canShareEmergencyContact: boolean;
    preferredLanguage?: LanguageCodes;
    visits?: PatientScreeningVisit[]
}

export const createUserAccount = (payload: SignupRequest) => {
    return {type: CREATE_ACCOUNT, payload}
}
export const accountCreated = (payload: { jwt: string, id: string, phoneNumber: string, nickname: string, userScreenerId: string, userScreenerSlug: string, preferredLanguage: LanguageCodes}) => {
    return {type: ACCOUNT_CREATED, payload}
}
export const accountCreationFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_CREATION_FAILED, payload}
}
export const accountSignin = (payload: {phoneNumber: string, password: string}) => {
    return {type: ACCOUNT_LOGIN, payload}
}
export const accountSignedIn = (payload: { jwt: string, id: string, phoneNumber: string, nickname: string, userScreenerId: string, userScreenerSlug: string, hasAcceptedTerms: boolean, preferredLanguage: LanguageCodes}) => {
    return {type: ACCOUNT_SIGNED_IN, payload}
}
export const accountSignInFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_SIGNIN_FAILED, payload}
}

export const patientTempLogin = (payload: {patientId: string, practiceId: string}) => {
    return {type: PATIENT_TEMP_LOGIN, payload}
}

export const patientTempLoggedIn = (payload: {jwt: string, id: string,
    phoneNumber: string, nickname: string, userScreenerId: string,
    userScreenerSlug: string, hasAcceptedTerms: boolean,
    preferredLanguage: LanguageCodes
}) => {
    return {type: PATIENT_TEMP_LOGGED_IN, payload}
}

export const patientTempLogInFailed = (payload: {error: string}) => {
    return {type: PATIENT_TEMP_LOG_IN_FAILED, payload}
}

export const sendVerificationCode = (phoneNumber: string) => {
    return { type: SEND_VERIFICATION_CODE, payload: { phoneNumber } }
}
export const checkIfVerificationCodeSent = (payload: { isSentSuccessfully: boolean, phoneNumber: string }) => {
    return {type: SEND_VERIFICATION_CODE_RESPONSE, payload}
}
export const checkIfVerificationCodeSentFailed = (payload: {error: string, isSentSuccessfully: boolean}) => {
    return {type: SEND_VERIFICATION_CODE_FAILED, payload}
}

export const verifyCode = (phoneNumber: string, verificationCode: string) => {
    return {type: VERIFY_CODE, payload : {phoneNumber, verificationCode}}
}

export const checkIfCodeVerifiedSuccessfully = (payload: { isVerified: boolean, phoneNumber: string }) => {
    return {type: VERIFY_CODE_RESPONSE, payload}
}
export const checkIfCodeVerifiedSuccessfullyFailed = (payload: {error: string, isVerified: boolean}) => {
    return {type: VERIFY_CODE_FAILED, payload}
}
export const clearErrorMessages = () => {
    return {type: CLEAR_ERROR_MESSAGES}
}

export const resetPassword = (payload: { phoneNumber: string, password: string }) => {
    return { type: RESET_PASSWORD, payload }
}
export const resetPasswordCompleted = (payload: { jwt: string, id: string, phoneNumber: string, nickname: string, userScreenerId: string, hasAcceptedTerms: boolean }) => {
    return {type: RESET_PASSWORD_RESPONSE, payload}
}
export const resetPasswordFailed = (payload: {error: string}) => {
    return {type: RESET_PASSWORD_FAILED, payload}
}

export const logoutUser = () => {
    return {type: ACCOUNT_SIGN_OUT}
}

export const logoutProvider = () => {
    return {type: PROVIDER_SIGN_OUT}
}

export const setNextUserScreenerId = (payload: {nextUserScreenerId: string}) => {
    return {type: SET_NEXT_USER_SCREENER_ID, payload}
}
export const setScreenerSlug = (payload: {nextScreenerSlug: string}) => {
    return {type: SET_SCREENER_SLUG, payload}
}

export const setPracticeId = (payload: {practiceId: string}) => {
    return {type: SET_PRACTICE_ID, payload}
}

export const getPracticeId = (payload: {slug: string}) => {
    return {type: GET_PRACTICE_ID, payload}
}

export const getPracticeIdFailed = (payload: {error: string}) => {
    return {type: GET_PRACTICE_FAILED, payload}
}

export const getPracticeIdSuccess = (payload: { practiceId: string }) => {
    return {type: GET_PRACTICE_SUCCESS, payload}
}

export const setSlug = (payload: {slug: string}) => {
    return {type: SET_PRACTICE_SLUG, payload}
}

export const providerLogin = (user: IProviderUser) => {
    return {type: PROVIDER_LOGIN, user}
}
export const providerLoginFailed = (payload: {error: string}) => {
    return {type: PROVIDER_LOGIN_FAILED, payload}
}
export const providerLoginSuccess = (provider: IAccount) => {
    return {type: PROVIDER_LOGIN_SUCCESS, provider}
}

export const setUserId = (payload: {userId: string}) => {
    return {type: SET_USER_ID, payload}
}

export const clearUserId = () =>{
    return {type: CLEAR_USER_ID}
}

export const patientScreenerStart = (payload: {
    userId: string,
    practiceType?: string,
    visitType: VisitTypeCodes,
    isInsuredByMedicare: boolean
}) => {
    return { type: PATIENT_SCREENER_START, payload }
}
export const patientScreenerStarted = (payload: {id: string, userScreenerId: string, userScreenerSlug: string}) => {
    return {type: PATIENT_SCREENER_STARTED, payload}
}
export const patientScreenerStartingFailed = (payload: {error: string}) => {
    return {type: PATIENT_SCREENER_STARTING_FAILED, payload}
}

export const acceptTerms = () => {
    return {type: ACCEPT_TERMS}
}
export const acceptTermsFailed = (payload: {error: string}) => {
    return {type: ACCEPT_TERMS_FAILED, payload}
}
export const acceptTermsSuccess = (payload: { isTermsAccepted: boolean }) => {
    return {type: ACCEPT_TERMS_SUCCESS, payload}
}
export const setLanguagePreference = (payload: {languagePreference: LanguageCodes}) => {
    return {type: SET_LANGUAGE_PREFERENCE, payload}
}

export const setScreenName = (payload: {screenName: string}) => {
    return {type: SET_SCREEN_NAME, payload}
}

export const patientScreenerVisits = (payload: {userId: string}) => {
    return {type: PATIENT_SCREENER_VISITS, payload}
}
export const patientScreenerVisitsFailed = (payload: {error: string}) => {
    return {type: PATIENT_SCREENER_VISITS_FAILED, payload}
}
export const patientScreenerVisitsSuccess = (payload: {visits: PatientScreeningVisit[]}) => {
    return {type: PATIENT_SCREENER_VISITS_SUCCESS, payload}
}
export const clearUserScreenerId = () => {
    return {type: CLEAR_USER_SCREENER_ID}
}

